//Home.js

import React from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import { regions } from "../utils/utils";
import { useAuth } from "../hooks/use-auth";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function Home(props) {
  const navigator = useNavigate();
  const auth = useAuth();

  const renderMenu = () => {
    const countries = regions.map((item) => {
      const len = item.regions.length;
      let n = 1;
      let bias = 1;
      let newRegions = [];
      if (item.country === "USA states") {
        n = 4;
      } else if (item.country === "Canada") {
        n = 3;
      }
      bias = len / n;
      bias = Math.ceil(bias);
      for (let i = 0; i < len; i += bias) {
        newRegions = newRegions.concat([item.regions.slice(i, i + bias)]);
      }
      return {
        country: item.country,
        regions: newRegions,
        n,
      };
    });
    return countries;
  };

  const handleNavigate = (code, region, country, revDate) => {
    console.log("===========    rev: ", revDate);
    navigator("/barcode", {
      state: {
        code,
        region,
        country,
        revDate,
      },
    });
  };

  return (
    <div className="container">
      {/* <div className='row'>
                <div className='col-md-3 col-sm-6'>list 1</div>
                <div className='col-md-3 col-sm-6'>list 2</div>
                <div className='col-md-3 col-sm-6'>list 3</div>
                <div className='col-md-3 col-sm-6'>list 4</div>
            </div> */}
      {renderMenu().map((item, index) => (
        <div className="mt-5 mb-5" key={`parent-state-${item.country}-key`}>
          <div
            className="mb-3"
            key={`${item.country}-index-${index}`}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h1>{item.country}</h1>
          </div>
          <div
            className="row"
            key={`${item.country}-div-region-group-${index}`}
          >
            {item.regions.map((subregions, index_1) => (
              <div
                key={`${item.country}-region-subgroup-${index_1}`}
                className={`col-md-${Math.floor(
                  12 / item.n
                )} col-sm-${Math.floor(12 / (item.n / 2))}`}
              >
                <ul
                  className="list-group"
                  key={`${item.country}-${item.n}-ul-list-${index_1}`}
                >
                  {subregions.map((name, index_2) => {
                    if ("state" in name && "items" in name) {
                      return (
                        <li
                          className="list-group-item disabled d-flex justify-content-between align-items-center"
                          key={`${item.country}-${name.region}-key-${index_2}`}
                        >
                          {name.region}
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success" // You can add custom color here
                          />
                        </li>
                      );
                    } else if ("state" in name) {
                      return (
                        <li
                          className="list-group-item disabled d-flex justify-content-between align-items-center"
                          key={`${item.country}-${name.label}-key-${index_2}`}
                        >
                          {name.label}
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success"
                          />
                        </li>
                      );
                    } else if ("items" in name) {
                      return (
                        <Accordion
                          key={`${item.country.replace(" ", "-")}-${
                            name.region
                          }-accordion`}
                        >
                          <Accordion.Item
                            eventKey={`${item.country.replace(" ", "-")}-${
                              name.region
                            }-accordion-list`}
                          >
                            <Accordion.Header>{name.region}</Accordion.Header>
                            <Accordion.Body>
                              {name.items.map((subname) => (
                                <div
                                  className="d-flex justify-content-between align-items-center"
                                  key={`${subname.label}-${index_2}`}
                                >
                                  <button
                                    style={{ textDecoration: "none" }}
                                    className={`btn btn-link ${
                                      subname?.state === false ? "disabled" : ""
                                    }`}
                                    onClick={() =>
                                      handleNavigate(
                                        subname.code,
                                        subname.label,
                                        item.country,
                                        subname.revDate
                                      )
                                    }
                                  >
                                    {subname.label}
                                  </button>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-success"
                                  />
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    }
                    return (
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center"
                        key={`${item.country}-${name.label}-key-${index_2}`}
                      >
                        <button
                          style={{ textDecoration: "none" }}
                          className="btn btn-link"
                          onClick={() =>
                            handleNavigate(
                              name.code,
                              name.label,
                              item.country,
                              name.revDate
                            )
                          }
                        >
                          {name.label}
                        </button>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="container mb-3">*Other states coming soon</div>
    </div>
  );
}
