//auth-context.js

import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import api from "../utils/utils";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const HANDLERS = {
  INITIALIZE: "INITIALIZE",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  LOADING_START: "LOADING_START", // Added
  LOADING_END: "LOADING_END", // Added
};

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const { user, isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated,
      user,
      isLoading: false, // Since we're initializing, loading effectively ends here.
    };
  },

  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  [HANDLERS.SIGN_OUT]: (state) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
  [HANDLERS.LOADING_START]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [HANDLERS.LOADING_END]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// The role of this context is to propagate authentication state through the App tree.

export const AuthContext = createContext(initialState);

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);
  const navigator = useNavigate();

  // useEffect(() => {
  //   const initData = async () => {
  //     dispatch({ type: 'LOADING_START' }); // Example action to set loading true
  //     const storedData = localStorage.getItem("barcode_user");
  //     if (storedData) {
  //       const { user, token } = JSON.parse(storedData);
  //       api.defaults.headers.common.Authorization = `Bearer ${token}`; // Ensure this is correctly set
  //       dispatch({ type: HANDLERS.SIGN_IN, payload: user });
  //     } else {
  //       dispatch({ type: HANDLERS.INITIALIZE, payload: null});
  //     }
  //     dispatch({ type: 'LOADING_END' }); // Example action to set loading false
  //   };

  //   initData();
  // }, []);

  useEffect(() => {
    dispatch({ type: HANDLERS.LOADING_START });
    const restoreState = async () => {
      try {
        const storedData = localStorage.getItem("barcode_user");
        if (storedData) {
          const { user, token } = JSON.parse(storedData);
          api.defaults.headers.common.Authorization = `Bearer ${token}`;
          dispatch({
            type: HANDLERS.INITIALIZE,
            payload: { user, isAuthenticated: true },
          });
        } else {
          dispatch({
            type: HANDLERS.INITIALIZE,
            payload: { user: null, isAuthenticated: false },
          });
        }
      } catch (error) {
        console.error("Error restoring state:", error);
        dispatch({
          type: HANDLERS.INITIALIZE,
          payload: { user: null, isAuthenticated: false },
        });
      } finally {
        dispatch({ type: HANDLERS.LOADING_END });
      }
    };

    restoreState();
  }, []);

  const signIn = async (email, password, recaptchaToken, otp = null) => {
    try {
        let payload = { email, password, recaptchaToken };
        if (otp) payload.otp = otp; // ✅ Include OTP if provided

        const response = await api.post(`/api/login`, payload);
        console.log("Backend response:", response.data); // ✅ Log response

        // ✅ If OTP is required, return response normally
        if (response.data?.msg === "OTP Required for Admin") {
            return response.data; // ✅ Don't throw an error
        }

        api.defaults.headers.common.Authorization = response.data.result.token;
        localStorage.setItem("barcode_user", JSON.stringify(response.data.result));

        dispatch({
            type: HANDLERS.SIGN_IN,
            payload: response.data.result.user,
        });

        return response.data.result.user;
    } catch (err) {
        console.error("Login error:", err.response?.data || err.message);

        // ✅ Handle OTP Required scenario (sometimes caught as an error)
        if (err.response?.data?.msg === "OTP Required for Admin") {
            return err.response.data; // ✅ Return the message instead of throwing
        }

        throw err.response?.data || { msg: "Login failed" };
    }
};


  const setUser = (user) => {
    dispatch({
      type: HANDLERS.SIGN_IN,
      payload: user,
    });
  };

  const signUp = async (email, name, password) => {
    try {
      const res = await api.post(`/api/register`, { email, name, password });
    } catch (err) {
      throw new Error("Please check your email and password");
    }
  };

  const signOut = () => {
    localStorage.removeItem("barcode_user"); // Correctly remove the user data
    api.defaults.headers.common["Authorization"] = null;
    dispatch({
      type: HANDLERS.SIGN_OUT,
    });
    navigator("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatch,
        signIn,
        signUp,
        signOut,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);
