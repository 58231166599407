//Pricing.js

import React, { useRef } from "react";
import { Card, Row, Col, Button, Modal } from "react-bootstrap";
// import CoinbaseCommerceButton from 'react-coinbase-commerce';
import { useAuth } from "../hooks/use-auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faCalendarDay,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { useConfig } from "../hooks/useConfig";

// const prices = [
//   {
//     size: "7 days/15 pcs",
//     expiresIn: 7,
//     amount: 10,
//     barcodes: 15,
//   },
//   // {
//   //   size: "20 days/300 pcs",
//   //   expiresIn: 20,
//   //   amount: 1,
//   //   barcodes: 300,
//   // },
//   {
//     size: "15 days/100 pcs",
//     expiresIn: 15,
//     amount: 50,
//     barcodes: 100,
//   },
//   {
//     size: "30 days/350 pcs",
//     expiresIn: 30,
//     amount: 90,
//     barcodes: 350,
//   },
//   {
//     size: "Unlimted/1000 pcs",
//     expiresIn: 36500,
//     amount: 200,
//     barcodes: 1000,
//   },
//   // {
//   //     size: 'Test',
//   //     amount: 1,
//   //     barcodes: 0,
//   //     url: 'https://commerce.coinbase.com/checkout/cc25bb78-a7b6-4a08-8281-c2c5c92207c5'
//   // }
// ];

export default function Pricing(props) {
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [showPaymentOptionsModal, setShowPaymentOptionsModal] =
    React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const linkBtn = useRef();
  const auth = useAuth();
  const navigator = useNavigate();
  const config = useConfig();
  const prices = config && config.prices ? config.prices : [];

  const handleAuthAndPurchase = (item) => {
    if (!auth.isAuthenticated) {
      setShowLoginModal(true);
    } else {
      setSelectedItem(item);
      setShowPaymentOptionsModal(true); // Show payment options modal for authenticated users
    }
  };

  const handlePurchase = async (item) => {
    const fetchPurchaseToken = async () => {
      try {
        const response = await axios.post("/api/generate-purchase-token");
        const data = response.data;
        return data.purchaseToken;
      } catch (error) {
        console.error("Error fetching purchase token:", error);
      }
    };

    const purchaseToken = await fetchPurchaseToken();
    const data = {
      name: item.size,
      description: "Money For Barcode",
      pricing_type: "fixed_price",
      local_price: {
        amount: item.amount,
        currency: "USD",
      },
      metadata: {
        // Include this line
        purchaseToken: purchaseToken, // Pass the purchaseToken here
        barcodes: item.barcodes,
        expiresIn: item.expiresIn,
      },
      redirect_url: "https://pdf417.idtempl.com",
    };
    const response = await fetch("https://api.commerce.coinbase.com/charges", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CC-Api-Key": config
          ? config.coinbaseApiKey
          : "fb27fe5d-c608-4359-ac43-7a5df3cc8961",
        // "X-CC-Api-Key": "da101cf9-2d08-49a0-821b-4d1d48054ad6",
        // 'X-CC-Api-Key': '2827bf0c-b3cc-4210-b75b-b5064c374318', // dfc0c262-1cbf-4cff-a38f-7791d6be6769
        "X-CC-Version": "2018-03-22",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    const res_data = result.data;
    console.log(res_data);
    // window.location.href = res_data.hosted_url;
    // linkBtn.current.href = item.url;
    linkBtn.current.href = res_data.hosted_url;
    linkBtn.current.click();
  };
  const onChargeSuccess = (msg) => {
    console.log("charge success: ", msg);
  };
  const onChargeFailure = (msg) => {
    console.log("charge failure: ", msg);
  };
  const onPaymentDetected = (msg) => {
    console.log("payment detected: ", msg);
  };

  // useEffect(() => {
  //     if(!auth.isAuthenticated){
  //         navigator('/login')
  //     }
  // }, [props])

  const handleNowPaymentsPurchase = async (item) => {
    const fetchPurchaseToken = async () => {
      try {
        const response = await axios.post("/api/generate-purchase-token");
        const data = response.data;
        return data.purchaseToken;
      } catch (error) {
        console.error("Error fetching purchase token:", error);
      }
    };

    const purchaseToken = await fetchPurchaseToken();

    const data = {
      price_amount: item.amount,
      price_currency: "USD",
      // pay_currency: "BTC", // Update to the desired cryptocurrency
      order_id: purchaseToken,
      ipn_callback_url: "https://pdf417.idtempl.com/api/nowpayments-webhook",
      order_description: `barcodes:${item.barcodes},expiresIn:${item.expiresIn}`,
      success_url: "https://pdf417.idtempl.com",
      cancel_url: "https://pdf417.idtempl.com",
    };

    console.log("api key", config.nowPaymentsApiKey);

    try {
      const response = await fetch("https://api.nowpayments.io/v1/invoice", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": config.nowPaymentsApiKey,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      console.log("NowPayments API Response:", result);

      if (result.invoice_url) {
        try {
          await axios.post("/api/nowpayments-webhook", {
            payment_id: result.id,
            order_id: result.order_id,
            order_description: result.order_description,
            price_amount: result.price_amount,
            price_currency: result.price_currency,
            payment_status: "waiting",
            created_at: result.created_at,
            code: result.token_id,
          });
          console.log("Manual webhook call successful");
        } catch (error) {
          console.error("Error in manual webhook call:", error);
        }
        console.log("Invoice URL:", result.invoice_url);
        // Redirect to the NowPayments invoice URL
        window.open(result.invoice_url, "_blank");
      } else {
        console.error("Invoice URL not provided in the response.");
      }

      // if (result.pay_address && result.pay_amount) {
      //   alert(
      //     `Send exactly ${result.pay_amount} ${result.pay_currency} to the address: ${result.pay_address}`
      //   );

      //   navigator("/payment-instructions", {
      //     state: {
      //       address: result.pay_address,
      //       amount: result.pay_amount,
      //       currency: result.pay_currency,
      //     },
      //   });
      // } else {
      //   console.error("Payment details not found in the NowPayments response");
      // }
    } catch (error) {
      console.error("Error creating NowPayments payment:", error);
    }
  };

  return (
    <>
      {showLoginModal && (
        <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Oops! You are not logged in!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            To purchase barcode package you need to login. If you already have
            account click "Login" button. If you don't have account yet, click
            "Register" button.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                /* navigate to login page */
                navigator("/login");
              }}
            >
              Login
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                /* navigate to registration page */
                navigator("/register");
              }}
            >
              Register
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showPaymentOptionsModal && (
        <Modal
          show={showPaymentOptionsModal}
          onHide={() => setShowPaymentOptionsModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Your Purchase</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h4>{selectedItem?.size}</h4>
            <div className="mb-3">
              <FontAwesomeIcon icon={faBarcode} /> {selectedItem?.barcodes}{" "}
              Pieces
            </div>
            <div className="mb-3">
              <FontAwesomeIcon icon={faDollarSign} /> ${selectedItem?.amount}
            </div>
            <div className="mb-4">
              <FontAwesomeIcon icon={faCalendarDay} /> {selectedItem?.expiresIn}{" "}
              Days
            </div>
            <Button
              className="px-4"
              variant="primary"
              size="lg"
              onClick={() => {
                handlePurchase(selectedItem); // Use the stored selected item
                setShowPaymentOptionsModal(false); // Close the modal after click
              }}
            >
              Proceed to Coinbase
            </Button>
            <Button
              className="mt-3"
              variant="dark"
              size="lg"
              onClick={() => {
                handleNowPaymentsPurchase(selectedItem); // Use the stored selected item
                setShowPaymentOptionsModal(false); // Close the modal after click
              }}
            >
              <div className="fw-bold">NOWPayments</div>
              <div style={{ fontSize: "0.6rem", color: "#ccc" }}>
                (BTC, LTC, USDT, ETH, USDC, DOGE, BUSD, BNB, More)
              </div>
            </Button>
          </Modal.Body>
        </Modal>
      )}
      <div className="container pb-5 px-5">
        <div className="my-4">
          <h3 className="text-center">Barcode packages</h3>
        </div>
        <Row xs={1} md={3} className="g-4">
          {prices.map((_item, idx) => (
            <Col key={idx}>
              <Card>
                <Card.Header>
                  <h3 className="text-center">{_item.size}</h3>
                </Card.Header>
                <Card.Body>
                  <Card.Title className="text-center mb-4">
                    <span className="fs-4 bold">${_item.amount}</span>
                  </Card.Title>
                  <div className="d-grid gap-2">
                    <Button
                      className="d-block"
                      variant="outline-primary"
                      onClick={() => handleAuthAndPurchase(_item)}
                    >
                      Buy
                    </Button>
                    {/* <CoinbaseCommerceButton
                                        checkoutId={'09a4f8f3-7707-4791-affe-4db05f71e4db'}
                                        onChargeSuccess={onChargeSuccess}
                                        onChargeFailure={onChargeFailure}
                                        onPaymentDetected={onPaymentDetected}
                                    />
                                    <div>
                                        <a className="buy-with-crypto"
                                            href="https://commerce.coinbase.com/checkout/09a4f8f3-7707-4791-affe-4db05f71e4db">
                                            Buy with Crypto
                                        </a>
                                        <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
                                        </script>
                                    </div> */}
                    <a ref={linkBtn} href="" target="_blank"></a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div className="container px-5 mt-5 mb-5">
        <h5 className="text-center mb-4">About packages</h5>
        <div>
          <p>
            To use our generator, you need to purchase packages with barcodes.
            We have a flexible barcode purchase system. Thus, you can purchase
            the quantity you need. The larger the quantity, the lower the price!
            Select a barcode package and make a purchase. At the moment, we
            accept payments only in cryptocurrencies. After payment confirmation
            (up to 40 minutes!) the available number of barcodes will be
            displayed in your profile. If you have any questions about the
            purchase of barcodes, please contact our online support.
          </p>
        </div>
      </div>
    </>
  );
}
