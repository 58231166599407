// Import the JSON dependencies data
import dependenciesData from './dependenciesData.json';

// A helper map for converting dependency strings to form field keys
const dependencyFieldMap = {
    "Issue date": "issue_date",
    "Birth date": "birth_date",
    "Expiry date": "expiry_date",
    "First name": "first_name",
    "Last name": "last_name",
    "DL Number": "DL_number",
    "ICN": "ICN",
    "Sex": "sex",
  };
  
  
  // A helper function to check dependencies
  export function checkFieldDependencies(region, field, data) {
    const stateInfo = dependenciesData.find(item => item.STATE === region);
    console.log(stateInfo, region);
    if (!stateInfo) return true;
  
    let dependencyKey = "";
    if (field === "DL_number") dependencyKey = "DL Number Dependency";
    else if (field === "ICN") dependencyKey = "ICN Dependency";
    else if (field === "DD") dependencyKey = "DD Dependency";
    else if (field === "expiry_date") dependencyKey = "Expiry date Dependency";
    else return true;
  
    const dependencyString = stateInfo[dependencyKey];
    if (!dependencyString || dependencyString.trim() === "") return true;
  
    const dependencies = dependencyString.split(",").map(d => d.trim());
  
    for (let dep of dependencies) {
      const formFieldName = dependencyFieldMap[dep];
      console.log(formFieldName, data);
      if (!formFieldName) continue;
      console.log(data[formFieldName]);
      if (!data[formFieldName] || data[formFieldName].trim() === "") {
        alert(`You must fill the ${dep} before generating random ${field}.`);
        return false;
      }
    }
    return true;
  }