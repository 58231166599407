// HowToCreateBarcode.js

import React from "react";

const HowToCreateBarcode = () => {
  return (
    <main className="font-monospace">
      {/* Page Header */}
      <div className="container d-flex justify-content-center pt-4 mb-4">
        <h3>How to Create Barcode</h3>
      </div>

      {/* Page Content */}
      <div className="container p-3">
        <p>
          This is a small guide on how to create barcodes using our website. In
          fact, it's very simple – just a couple of minutes, and a valid and
          readable barcode is ready. Let's go!
        </p>

        <p>
          First of all, you need to{" "}
          <a href="https://pdf417.idtempl.com/login/">login</a> or{" "}
          <a href="https://pdf417.idtempl.com/register/">register</a> on the
          website. It's very simple, just login and password and no email
          confirmation. Ready? Great, let's move on!
        </p>

        <p>
          Then go to the <a href="https://pdf417.idtempl.com/states/">states page</a>{" "}
          and select the required state:
        </p>

        <p>
          <img
            alt="Available US states list"
            className="d-block img-fluid mx-auto"
            src="/States.png"
            style={{ maxHeight: "450px" }}
          />
        </p>

        <p>
          Now you need to enter the data in all fields. This is the most
          important stage. It's very easy to make a mistake here. Be careful!
          The data that you enter <strong>must strictly correspond</strong> to
          the prompts in the fields. If you don't know what format to specify
          the data in, use the tooltips (a question mark next to the field
          name).
        </p>

        <p>
          Data such as the inventory control number (ICN) and the document
          discriminator (DD) can be generated automatically. To do this, click
          on the calculator icon:
        </p>

        <p>
          <img
            alt="State fields"
            className="d-block img-fluid mx-auto"
            src="/California.png"
            style={{ maxHeight: "495px" }}
          />
        </p>

        <p>
          After you enter all the data, please <strong>double-check</strong>{" "}
          them again! Then click the Create button and in a moment your barcode
          will be ready and will appear below. You can download it immediately
          in a format that is convenient for you (png and svg are supported).
        </p>

        <p>
          <img
            alt="Barcode download button"
            className="d-block img-fluid mx-auto"
            src="/Generated.png"
            style={{ maxHeight: "550px" }}
          />
        </p>

        <p>
          Your barcode was automatically saved and the order history, which you
          can see on the <a href="https://pdf417.idtempl.com/profile">account</a> page.
          Here you can download the barcode again or delete it from the order
          history. It also displays information about the number of available
          barcodes.
        </p>

        <p>
          <img
            alt="History page"
            className="d-block img-fluid mx-auto"
            src="/Profile.png"
            style={{ maxHeight: "335px" }}
          />
        </p>
      </div>
    </main>
  );
};

export default HowToCreateBarcode;
