//Main.js

import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

export default function Main(props) {

    useEffect(() => {
        // const stringdata = localStorage.getItem('barcode_user')
        // const data = JSON.parse(stringdata)
        // console.log(data)
        // if(data){
        //     auth.setUser(data.user)
        //     // localStorage.setItem('barcode_token', data.token)
        //     api.defaults.headers.common.Authorization = data.token;
        // }
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='container'>
            <div className='container mt-5'>
                <div className='text-center'>
                    <h1>PDF417.IDTEMPL.COM</h1>
                </div>
                <div className='text-center'>
                    <p>Simple and powerful service for creating 100% valid<br /> PDF417 barcodes for a US Driver's License.</p>
                </div>
                <div className='d-flex justify-content-center'>
                    <Link to={`/states`} className='me-2' onClick={() => props.setNavbar('states')}><Button variant='outline-primary'>Get started</Button></Link>
                    <a href='#features' className='me-2'><Button variant='outline-success'>Our features</Button></a>
                    <a href='#howwork' className='me-2'><Button variant='outline-secondary'>How does it work?</Button></a>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <div className='me-3 pt-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                                </svg>
                            </div>
                            <div>
                                <h3>Validity 100%</h3>
                                <div style={{ fontSize: 14 }}>
                                    <p>We create barcodes in accordance with official AAMVA documentation, so they are exactly the same as the original documents barcodes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <div className='me-3 pt-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"></path>
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                                </svg>
                            </div>
                            <div>
                                <h3>Best price</h3>
                                <div style={{ fontSize: 14 }}>
                                    <p>We offer the best price on the market. On average, one barcode costs 2 times cheaper than competitors. Take a look at our prices and you will be surprised!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <div className='me-3 pt-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-emoji-smile" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                    <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"></path>
                                </svg>
                            </div>
                            <div>
                                <h3>Best price</h3>
                                <div style={{ fontSize: 14 }}>
                                    <p>We offer the best price on the market. On average, one barcode costs 2 times cheaper than competitors. Take a look at our prices and you will be surprised!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div className='container' id='features'>
                <div className='row align-items-center'>
                    <div className='col-md-6 col-sm-6'>
                        <div className='d-flex flex-column'>
                            <div style={{ paddingRight: 32 }}>
                                <h3>Check barcode with any scanner</h3>
                                <p style={{ fontSize: 22 }}>If the barcode is invalid, we guarantee a refund. Take a look at the verify manual.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-6 p-5'>
                        <img className='w-100 h-100' src='images/scan_apps.png' alt='scan apps' />
                    </div>
                </div>
                <hr />
                <div className='row align-items-center'>
                    <div className='col-md-6 col-sm-6 p-5'>
                        <video className='w-100' src='images/data_generator_demo.webm' muted autoPlay loop />
                    </div>
                    <div className='col-md-6 col-sm-6'>
                        <div className='d-flex flex-column'>
                            <div style={{ paddingRight: 32 }}>
                                <h3>Data generators</h3>
                                <p style={{ fontSize: 22 }}>
                                    Use built-in data generators to make your work easier. Correct Document Discriminator and Inventory Control Number will be calculated instantly!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='row align-items-center'>
                    <div className='col-md-6 col-sm-6'>
                        <div className='d-flex flex-column'>
                            <div style={{ paddingRight: 32 }}>
                                <h3>API for developers and not only</h3>
                                <p style={{ fontSize: 22 }}>
                                    Use the API to automate your work or develop your own products. Copy and run the code on the right and the barcode will be ready!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-6'>
                        <div style={{ height: 200, background: '#f8f8f8', overflow: 'auto', width: 'auto', border: 'solid gray', borderWidth: '.1em .1em .1em .8em', padding: '.2em .6em' }}>
                            <pre style={{ height: '100%', margin: 0, lineHeight: '125%' }}><span style={{ color: '#AA22FF', fontWeight: 'bold' }}>import</span> <span style={{ color: '#0000FF', fontWeight: 'bold' }}>requests</span><br/>

                                <span style={{ color: '#008800', fontStyle: 'italic' }}># Set header for authentication</span><br/>
                                headers <span style={{ color: '#666666' }}>=</span> {"{"} <span style={{ color: '#BB4444' }}>'AUTH-TOKEN'</span>: <span style={{ color: '#BB4444' }}>'6c1a0f0f-ce4e-4c73-8f97-31484ce8551e'</span> {"{"}<br/>

                                <span style={{ color: '#008800', fontStyle: 'italic' }}># Get Califonia fields info</span><br/>
                                params <span style={{ color: '#666666' }}>=</span> {"{"} <span style={{ color: '#BB4444' }}>'state'</span>: <span style={{ color: '#BB4444' }}>'CA'</span>{"}"}<br/>
                                response <span style={{ color: '#666666' }}>=</span> requests<span style={{ color: '#666666' }}>.</span>get(<span style={{ color: '#BB4444' }}>'https://pdf417.idtempl.com/api/get_barcode_fields_brief_info/'</span>,<br/>
                                params<span style={{ color: '#666666' }}>=</span>params, headers<span style={{ color: '#666666' }}>=</span>headers)<br/>

                                <span style={{ color: '#008800', fontStyle: 'italic' }}># These are our fields with default values. You can change it</span><br/>
                                data <span style={{ color: '#666666' }}>=</span> response<span style={{ color: '#666666' }}>.</span>json()<br/>
                                <span style={{ color: '#AA22FF' }}>print</span>(data)<br/>

                                <span style={{ color: '#008800', fontStyle: 'italic' }}># Generate barcode</span><br/>
                                response <span style={{ color: '#666666' }}>=</span> requests<span style={{ color: '#666666' }}>.</span>post(<span style={{ color: '#BB4444' }}>'https://pdf417.idtempl.com/api/generate_barcode/'</span>,<br/>
                                headers<span style={{ color: '#666666' }}>=</span>headers, json<span style={{ color: '#666666' }}>=</span>data)<br/>
                                <span style={{ color: '#AA22FF' }}>print</span>(response<span style={{ color: '#666666' }}>.</span>json())<br/>

                                <span style={{ color: '#008800', fontStyle: 'italic' }}># Download barocde</span><br/>
                                response <span style={{ color: '#666666' }}>=</span> requests<span style={{ color: '#666666' }}>.</span>get(<span style={{ color: '#BB4444' }}>'https://pdf417.idtempl.com'</span> <span style={{ color: '#666666' }}>+</span> response<span style={{ color: '#666666' }}>.</span>json()[<span style={{ color: '#BB4444' }}>'file_png'</span>], allow_redirects<span style={{ color: '#666666' }}>=</span><span style={{ color: '#AA22FF', fontWeight: 'bold' }}>True</span>)<br/>
                                <span style={{ color: '#AA22FF' }}>open</span>(<span style={{ color: '#BB4444' }}>'barcode.png'</span>, <span style={{ color: '#BB4444' }}>'wb'</span>)<span style={{ color: '#666666' }}>.</span>write(response<span style={{ color: '#666666' }}>.</span>content)<br/>
                            </pre>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='row align-items-center'>
                    <div className='col-md-6 col-sm-6' style={{ padding: 64 }}>
                        <img className='w-100' src='images/referral_program.png' alt='referral image' />
                    </div>
                    <div className='col-md-6 col-sm-6'>
                        <div className='d-flex flex-column'>
                            <div style={{ paddingRight: 32 }}>
                                <h3>Referral program</h3>
                                <p style={{ fontSize: 22 }}>
                                    Invite your friends via a personal link and get a percentage from each of their purchases! Read more.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='row align-items-center'>
                    <div className='col-md-6 col-sm-6'>
                        <div className='d-flex flex-column'>
                            <div style={{ paddingRight: 32 }}>
                                <h3>Flexible barcodes purchase system</h3>
                                <p style={{ fontSize: 22 }}>
                                    Buy the quantity you need. The larger the quantity, the lower the price!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-6'>
                        <img className='w-100' src='images/barcode_packages_preview.jpg' alt='barcode image' />
                    </div>
                </div>
                <hr />
            </div>
            <div className='container mt-5' id='howwork'>
                <div>
                    <h3 className='text-center'>F.A.Q</h3>
                </div>
                <div className='row'>
                    <div className='col-md-4 col-sm-6 p-3'>
                        <div className='p-3 border rounded-2'>
                            <div>
                                <h4>How to create barcode?</h4>
                            </div>
                            <div>
                                <p>A little guide on how to create barcodes using our website. In fact, it's very simple – just a couple of minutes, and a valid and readable barcode is ready.</p>
                            </div>
                            <div className='d-flex justify-content-end'>
                            <Link to="/articles/how-to-create-barcode" className='btn btn-link'>{`Read more ->`}</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 p-3'>
                        <div className='p-3 border rounded-2'>
                            <div>
                                <h4>How to purchase barcodes packages?</h4>
                            </div>
                            <div>
                                <p>This article will help you buy barcode packages. It's fast, secure and anonymous.</p>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <a className='btn btn-link'>{`Read more ->`}</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 p-3'>
                        <div className='p-3 border rounded-2'>
                            <div>
                                <h4>How to check the generated barcode?</h4>
                            </div>
                            <div>
                                <p>There are several great apps for checking the validity of the generated barcode.</p>
                            </div>
                            <div className='d-flex justify-content-end'>
                            <Link to="/articles/how-to-check-barcode" className='btn btn-link'>{`Read more ->`}</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 p-3'>
                        <div className='p-3 border rounded-2'>
                            <div>
                                <h4>Referral program guide</h4>
                            </div>
                            <div>
                                <p>Do you want to earn money by inviting friends? This article will help you figure out how to use our referral program.</p>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <a className='btn btn-link'>{`Read more ->`}</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-6 p-3'>
                        <div className='p-3 border rounded-2'>
                            <div>
                                <h4>API usage tutorial</h4>
                            </div>
                            <div>
                                <p>Do you want to automate your work? This tutorial will show you how to set up and start using our API.</p>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <a className='btn btn-link'>{`Read more ->`}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <h1 className='text-center'>About us</h1>
                <div>
                    <p>
                    The goal of our team is to create the best service for creating valid barcodes for US driver's licenses. We are open to your suggestions and advice. Join our Telegram channel to keep up to date with all the news. You can also contact us in any way convenient for you.
                    </p>
                </div>
                <div className='d-flex justify-content-center'>
                    <Button className='me-2' variant='primary' size='sm'>
                        <span className='me-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path>
                            </svg>
                        </span>
                        News
                    </Button>
                    <a href='https://t.me/IDTemaplate' className='btn btn-outline-primary me-2' variant='outline-primary' size='sm'>
                        <span className='me-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path>
                            </svg>
                        </span>
                        Telegram
                    </a>
                    <a href='https://wa.me/+13473435837' className='btn btn-outline-success me-2' variant='outline-success' size='sm'>
                        <span className='me-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                            </svg>
                        </span>
                        Whatsapp
                    </a>
                    <a href='mailto:idtemplate.psd@gmail.com' className='btn btn-outline-warning me-2' variant='outline-warning' size='sm'>
                        <span className='me-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
                                <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"></path>
                                <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"></path>
                            </svg>
                        </span>
                        Mail
                    </a>
                </div>
            </div>
        </div>
    )
}