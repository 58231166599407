// HowToCheckBarcode.js

import React from "react";

const HowToCheckBarcode = () => {
  return (
    <main className="font-monospace">
      {/* Page Header */}
      <div className="container d-flex justify-content-center pt-4">
        <h3>How to Check Barcode</h3>
      </div>

      {/* Page Content */}
      <div className="container p-3">
        <p>
          This is a tutorial on how to check barcodes generated with our
          website. There are a lot of reasons why the barcode may not pass
          verification, so you should always check the barcodes before using
          them for your work. Here are the programs and tips for checking
          barcodes. Let's go!
        </p>

        <p>
          So, you have created a barcode (following the recommendations from{" "}
          <a href="/articles/how-to-create-barcode/">
            this manual
          </a>
          ). Open your profile page and open your barcode:
        </p>
        <p>
          <img
            alt="Profile page and orders history"
            className="d-block img-fluid mx-auto"
            src="/Check1.png" // Adjusted path based on your public folder
            style={{ maxHeight: "380px" }}
          />
        </p>

        <h3>Manual Check</h3>

        <p>
          Some useful information is displayed above the barcode. There is a
          last name and first name 🔴, document number 🟡, date of birth 🔵,
          date of issue 🟣, and expiration date 🟢. Make sure that this is
          exactly the data you need.
        </p>

        <p>
          <img
            alt="Barcode preview in profile page"
            className="d-block img-fluid mx-auto"
            src="/Check2.png" // Adjusted path based on your public folder
            style={{ maxHeight: "380px" }}
          />
        </p>

        <h3>Software Check</h3>

        <p>
          Now we will show you how to check the created barcode using special
          applications. You can install them on your phone and use them
          absolutely for free.
        </p>

        <h4>Scandit</h4>

        <p>
          Available in{" "}
          <a href="https://apps.apple.com/app/scandit-barcode-scanner/id453880584">
            App Store
          </a>{" "}
          and{" "}
          <a href="https://play.google.com/store/apps/details?id=com.scandit.demoapp">
            Google Play
          </a>. Open the app and follow the instructions in the picture below.
        </p>

        <p>
          <img
            alt="Scandit application for barcode checking"
            className="d-block img-fluid mx-auto"
            src="/Check3.jpg" // Adjusted path based on your public folder
            style={{ maxHeight: "615px" }}
          />
        </p>

        <hr />

        <h4>Show-Me ID</h4>

        <p>
          Available in{" "}
          <a href="https://apps.apple.com/app/show-me-id/id1552200807">
            App Store
          </a>{" "}
          and{" "}
          <a href="https://play.google.com/store/apps/details?id=gov.mo.dps.atc">
            Google Play
          </a>. Open the app and follow the instructions in the picture below.
        </p>

        <p>
          <img
            alt="Show-me ID application for barcode checking"
            className="d-block img-fluid mx-auto"
            src="/Check4.jpg" // Adjusted path based on your public folder
            style={{ maxHeight: "630px" }}
          />
        </p>

        <hr />

        <h4>BCS ID Scanner</h4>

        <p>
          Available in{" "}
          <a href="https://apps.apple.com/app/bar-club-stats-id-scanner/id523018379">
            App Store
          </a>{" "}
          (only USA region) and{" "}
          <a href="https://play.google.com/store/apps/details?id=com.barclubstats2">
            Google Play
          </a>. Open the app and follow the instructions in the picture below.
        </p>

        <p>
          <img
            alt="BCS ID Scanner application for barcode checking"
            className="d-block img-fluid mx-auto"
            src="/Check5.jpg" // Adjusted path based on your public folder
            style={{ maxHeight: "585px" }}
          />
        </p>
      </div>
    </main>
  );
};

export default HowToCheckBarcode;
